html, body, #root {
  height: 100%;
  background-color: #f6f9fa;
}
input:focus {
  border: 1px solid #CCC;
  outline: none;
  box-shadow: 0 1px 5px #EEE;
}

button:focus {
  outline: none;
  box-shadow: 0 2px 5px #CCC;
}

select:focus {
  outline: none;
  border: 1px solid #CCC;
  box-shadow: 0 1px 5px #EEE;
}

#root {
  height: 100%;
  background-color: #f6f9fa;
  display: flex;
}
.App {
  height: 100%;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.Login {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.Login-Box {
  width: 300px;
  background-color: #FFF;
  border-radius: 5px;
  align-self: center;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 3px 8px #EEE;
  display: flex;
  flex-direction: column;
}

.Login-Logo {
  width: 60%;
  align-self: center;
  padding-bottom: 20px;
}

.Loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;

}
.Loading img {
  width: 40px;
  height: 40px;
  align-self: center;
}

.LoginError {
  color: #AA0000;
  font-size: 14px;
  text-align: center;
}

.FormError {
  color: #AA0000;
  font-size: 14px;
}
