.alert {
  width: 500px;
  height: auto;
  background: #fff;
  align-self: center;
  box-shadow: 0 5px 10px #3336;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  p {
    margin-top: 0;
  }
  h2 {
    margin: 0;
    padding: 0;
  }

  &-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: #00000066;
    overflow: auto;
  }
  .FormButton {
    width: auto;
    margin: 20px 0 0 0;
  }
}

.crop {
  align-self: center;
  border-radius: 5px;
}

.Header {
  margin: 10px;
  height: 50px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.Header .Logo {
  height: 50px;
  width: auto;
  cursor: pointer;
}

.Header {
  position: relative;
  .Menu {
    align-self: center;
    flex-grow: 1;
    margin-left: 20px;
    a {
      color: #999;
      text-decoration: none;
      padding: 20px;
    }
    .active {
      color: #000;
    }
  }
  .MenuRight {
    margin-top: 20px;
    align-self: center;
    a {
      color: #999;
      text-decoration: none;
    }
    .active {
      color: #000;
    }
    * {
      align-self: center;
      margin-left: 20px;
    }
  }

  .MenuUser {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
  }
}

.LinkBoxList {
  margin-bottom: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: space-between;
  display: flex;

  .LinkBox {
    background-color: #fff;
    color: #333;
    text-decoration: none;
    font-size: 18px;
    border-radius: 5px;
    width: 32%;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    align-self: center;
    height: 100px;
    display: flex;
    box-shadow: 0 3px 8px #eee;
    flex-direction: column;
    text-align: center;
    justify-content: center;

    &:hover {
      background-color: #fefefe;
    }
  }
}

.ListHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.ListItem {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
  align-self: center;
  display: flex;
  box-shadow: 0 3px 8px #eee;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: #fefefe;
    box-shadow: 0 3px 10px #ddd;
  }
}

.ListItemEdit {
  color: #333;
  padding: 5px;
  align-self: flex-end;
}

.TopBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  h1 {
    font-size: 20px;
    padding: 0;
    margin: 0;
  }
  .DataInfoButtons > * {
    display: inline;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px 20px;
    color: #000;
    text-decoration: none;
    box-shadow: 0 3px 8px #eee;
    cursor: pointer;

    &.blue {
      background-color: #2663d5;
      color: #fff;
    }
    &:hover {
      box-shadow: 0 3px 10px #ddd;
    }
  }
  .blue {
    display: inline;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px 20px;
    color: #000;
    text-decoration: none;
    box-shadow: 0 3px 8px #eee;
    background-color: #2663d5;
    color: #fff;
  }
}
.FullForm {
  input,
  select {
    width: 100%;
    box-sizing: border-box;
  }
}

.IconLink {
  cursor: pointer;
}

.DataInfoButtons > * {
  margin-left: 10px;
  &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
  }
  &.DataInfoApproved {
    background: #009a00;
    color: #FFF;
    cursor: initial;
  }

  &.WithdrawApproval {
    cursor: pointer;
  }
}


.DataItemLink {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.DataPurpose, .edit, .trash, .close {
  z-index: 2;
}
.DataInfoRefresh {
  svg {
    margin-right: 10px;
  }
}

[data-rmiz-modal-content] img {
  width: 100%;
}

.DataInfoGrid {
  flex-wrap: wrap;
  width: 70%;
  display: flex;
  margin: 0 -10px 0 10px;
  & > div {
    margin: 0 10px;
    width: calc(25% - 20px);
    height: min-content;

    img {
      width: 100%;
      height: auto;
      box-shadow: 0 3px 8px #eee;
      border-radius: 5px;
      box-sizing: border-box;

      &.error {
        box-shadow: none;
        &::before {
          content: 'No data to display';
        }
      }
    }
  }
}

.DataInfo {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  margin-top: 20px;
  align-self: center;
  display: flex;
  box-shadow: 0 3px 8px #eee;
  position: relative;

  & > * {
    text-align: center;
    margin: 20px;
    flex-grow: 1;
  }

  .DataInfoMainImage {
    height: 100px;
    width: 100px;
    flex-grow: 0;
    border-radius: 5px;
  }
  & > .correct, & > .checking, & > .wrong {
      height: 3px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    & > .correct { background: #00aa0099; }
    & > .checking { background: #2663d5; }
    & > .wrong { background: #aa000099; }
}

.DataInfoNoLog {
  text-align: center;
  margin-top: 40px;
}

.DataInfoProcess {
  display: flex;
  margin-top: 20px;
  
  .DataInfoLog {
    width: 30%;
    .loader {
      height: 20px;
      float: right;
    }
    .log {
      background: #fff;
      width: 100%;
      border: 0;
      box-shadow: 0 3px 8px #eee;
      padding: 10px;
      font-family: Arial, Helvetica, sans-serif;
      outline: none;
      height: 250px;
      border-radius: 5px;
      box-sizing: border-box;
      overflow-y: scroll;
      white-space: break-spaces;
      font-size: 14px;
    }
  }
}


.DataItem:not(.DataItemEmpty) {
  overflow: hidden;
  transition: all ease-in-out 0.2s;

  &:hover {
    box-shadow: 0 8px 20px #ddd;
    // transform: translateY(-2px);
    cursor: pointer;
    transform: scale(1.02);
  }
  &:active {
    // transform: scale(1);
  }
  .FormInput {
    padding: 5px;
    margin: 5px 0 0 0;
  }

  .FormSelect {
    padding: 5px;
    margin: 5px 0 0 0;
  }

  & .DataItemRow {
    & > .correct {
      height: 3px;
      width: 100%;
      background: #00aa0099;
      position: absolute;
      top: 0;
      left: 0;
    }
    & > .checking {
      height: 3px;
      width: 100%;
      background: #2663d5;
      position: absolute;
      top: 0;
      left: 0;
    }
    & > .wrong {
      height: 3px;
      width: 100%;
      background: #aa000099;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .StatusButtons {
    opacity: 0;
    bottom: -18px;
    left: 0;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    button {
      cursor: pointer;
      margin: 5px;
      padding: 7px 10px;
      background: rgb(255, 251, 0);
      border-radius: 5px;
      border: none;
      box-shadow: 0px 1px 2px #aaaa;
      color: #fff;
      &:hover {
        box-shadow: 0px 2px 3px #aaaa;
      }
      &.correct {
        background: #2663d5;
      }
      &.wrong {
        background: #666;
      }
    }
  }
  &:hover {
    // overflow: initial;
  }
  &:hover .StatusButtons {
    opacity: 1;
  }
  .lastResult {
    font-size: 14px;
    font-weight: bold;

    svg {
      margin-right: 5px;
      font-size: 12px;
      opacity: 0.5;
      cursor: pointer;
    }
  }
}

.ReportList {
  cursor: pointer;
  .ListHeader > div,
  .ListItem > div {
    &:nth-child(1) {
      width: 10%;
    }
    &:nth-child(2) {
      width: 10%;
      text-align: center;
    }
    &:nth-child(3) {
      width: 15%;
      text-align: center;
    }
    &:nth-child(4) {
      width: 15%;
      text-align: center;
    }
    &:nth-child(5) {
      width: 5%;
      text-align: center;
    }
    &:nth-child(6) {
      width: 12%;
      text-align: right;
    }

    .Proggress {
      height: 36px;
      .CircularProgressbar-text {
        font-size: 28px;
      }
    }
  }
}

.Form {
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
  align-self: center;
  display: flex;
  box-shadow: 0 3px 8px #eee;
  flex-direction: column;
}
.Form .FormLabel:first-child {
  margin-top: 0;
}
.FormLabel {
  font-size: 12px;
  padding-left: 5px;
  margin-top: 10px;
}

.FormContent {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.FormContentInputs {
  display: flex;
  margin-left: 10px;
  width: 100%;
  flex-direction: column;
}

.FormContentInputs button {
  width: 100px;
  align-self: flex-end;
}
.FormContentBottom {
  display: flex;
}

.FormContentBottom input {
  flex-grow: 1;
}

.DataList {
  margin-bottom: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: space-between;
  display: flex;
}

.DataItem {
  background-color: #fff;
  border-radius: 5px;
  width: 32%;
  padding: 20px 20px 10px 20px;
  box-sizing: border-box;
  margin-top: 20px;
  align-self: center;
  height: 200px;
  display: flex;
  box-shadow: 0 3px 8px #eee;
  flex-direction: column;
  position: relative;
  justify-content: stretch;
}

.DataItem .DataItemRow {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    height: 120px;
}

.DataItem .DataPurpose {
    font-size: 14px;
    cursor: pointer;
    padding: 0;
    margin: 8px -10px 0 -10px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    label {
      cursor: pointer;
      user-select: none;
    }
    input {
      display: block;
      float: left;
      margin-right: 5px;
    }
    & > * {
      padding: 0 10px;
    }

    .lastResult {
      flex-grow: 1;
      text-align: right;
    }
}

.DataItem img {
  height: 100%;
  width: auto;
  margin-right: 10px;
  border-radius: 5px;
}

.DataItem .close {
  font-size: 14px;
  cursor: pointer;
}

.DataItem .edit {
  font-size: 12px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 0px;
  color: #666;
}

.DataItem .trash {
  font-size: 12px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
  color: #666;
}

.DataItem .save {
  font-size: 12px;
  margin-right: 10px;
  padding: 0;
  cursor: pointer;
  color: #666;
  flex-grow: 1;
  text-align: right;

  .FormButton {
    padding: 0 10px;
    margin: 0;
    height: 30px;
  }
}

.DataItem .edit:hover,
.DataItem .trash:hover {
  color: #000;
}

.DataItemEmpty {
  background: none;
  box-shadow: none;
}

.DataItemContent {
  display: flex;
  overflow-wrap: break-word;
  overflow: hidden;
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 1;
}

.DataItemContent small {
  color: #999;
  font-size: 10px;
}

.DataItemProp {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.DataItemSource {
  font-size: 12px;
  max-height: 50px;
  overflow: hidden;
}

.DataItemProp a {
  color: #333;
  text-decoration: none;
}
.DataItemProp a:hover {
  color: #333;
  text-decoration: underline;
}

.DataItemCreator {
  font-size: 12px;
}

.GenerateRaportSets {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 -15px;
  & > * {
    padding: 10px 20px;
  }
}

.GenerateRaportSet {
  padding-top: 10px;
  & > label {
    border: 1px solid #eee;
    border-radius: 5px;
    display: block;
    padding: 5px 20px 5px 5px;
    margin: 5px 0;
    user-select: none;
    white-space: nowrap;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
    }

    input {
      margin-right: 10px;
    }
  }
}

.FormButton {
  padding: 10px;
  margin: 5px;
  box-sizing: border-box;
  border-radius: 5px;
  background: #2663d5;
  border: none;
  cursor: pointer;
  color: #fff;
  &:disabled {
    opacity: 0.5;
  }
}

.FormInput {
  border: 1px solid #eee;
  padding: 10px;
  margin: 5px;
  box-sizing: border-box;
  border-radius: 5px;
}

.FormSelect {
  border: 1px solid #eee;
  padding: 5px 10px;
  margin: 5px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 5px;
  height: 33px;
}

.UploadImage {
  width: 150px;
  height: 130px;
  background-color: #eee;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  border: 1px solid #eee;
}

.UploadImage:hover {
  background-color: #ddd;
}

.UploadImage i {
  align-self: center;
  font-size: 30px;
  position: absolute;
  color: #aaa;
}

.UploadImage:hover i {
  color: #666;
}

.UploadImage input {
  opacity: 0;
  cursor: pointer;
}

.UploadImage img {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ReportNotEnough {
  text-align: center;
  flex-grow: 1;
  align-self: center
}
.ReportCharts {
  display: flex;
}
.ReportChart {
  height: 220px;
  padding: 20px;
  margin-top: 20px;
  align-self: center;
}

.ReportLabel {
  display: flex;
  margin-bottom: 5px;
}

.LabelColor {
  border-radius: 5px;
  width: 20px;
  display: block;
  height: 20px;
  margin-right: 10px;
}

.LabelName {
  width: 200px;
}

.LabelCount {
}

.ReportGenerateFrom {
  .FormButton {
    align-self: flex-end;
  }
}
.ReportResultHeader {
  display: flex;
  padding: 20px;
  & > * {
    width: 50%;
    display: flex;
    text-align: center;
    justify-content: center;
  }
}
.ReportSplit {
  display: flex;
  & > * {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    flex-flow: wrap;
    align-content: center;
    & > * {
      flex-grow: 1;
      width: 40%;
      display: flex;
      background-color: #fff;
      border-radius: 5px;
      border-radius: 5px;
      padding: 10px;
      margin: 10px 5px;
      box-sizing: border-box;
    }
  }
  .ReportSplitLine {
    width: 1px;
    background-color: #ddd;
    margin: 10px;
  }
}

.ReportResultItem {
  height: 105px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  background: transparent;
  padding: 0;

  & > .DataItemRow {
    &.deleted {
      opacity: 0.5;
    }

    & > .correct {
      height: 3px;
      width: 100%;
      background: #00aa0099;
      position: absolute;
      top: 0;
      left: 0;
    }
    & > .wrong {
      height: 3px;
      width: 100%;
      background: #aa000099;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &Info {
    display: flex;
    justify-content: space-between;
    height: 50px;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #FFF;
    box-shadow: 0 3px 8px #eee;

    & > img {
      width: 50px;
      height: auto;
      border-radius: 5px;
    }
    & > .result {
      font-weight: bold;
      font-size: 22px;
      align-self: center;
    }

    & > .status {
      font-size: 13px;
      display: flex;
      padding: 10px;
      flex-grow: 1;
      font-weight: bold;
      align-self: center;
      justify-content: space-between;
      img {
        height: 20px;
        margin-left: 10px;
        width: 20px;
      }
      &+.result {
        display: none;
      }
      &.success {
        color: #060;

        &+.result {
          display: block;
        }
      }
      &.error {
        color: #600;
      }
    }
  }

  &Actions {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    background: #FFFFFFAA;
    margin: 0 10px 5px 10px;
    box-shadow: 0 3px 8px #eee;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    

    .groups {
      display:flex;
      justify-content: space-between;
      button {
        opacity: 0.5;
        background: #2663d5;
        color: #fff;
      }
    }

    button {
      border: none;
      border-radius: 5px;
      padding: 2px 5px;
      border: 1px solid #2663d5; 
      display: inline-block;
      cursor: pointer;

      &.destructive {
        color: #a00;
        border: 1px solid #a00;
        background: transparent;
        &:hover {
          background: #a00;
          color: #fff;
        }
      }
    }
  }
}

.data-list-options {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  justify-content: space-between;
  &.bottom {
    margin-bottom: 20px;
  }
}

.approved {
  font-size: 11px;
  color: #00b300;
  font-weight: bold;
  .approved-icon {
    font-size: 14px;
    margin-right: 5px;
  }

  &.not-approved {
  font-weight: normal;
  color: #999;
  }
}

.pagination {
  
  display: flex;
  flex-direction: row;
  background: #fff;
  box-shadow: 0 3px 8px #eee;
  border-radius: 5px;
  align-self: center;
  & > * {
    white-space: nowrap;
    padding: 10px 15px;
    color: #000;
    background: #fff;
    border: 0;
    cursor: pointer;
    border-left: 1px solid #eee;
    box-shadow: none;
    transition: all 0.3s;
    &:focus {
      box-shadow: none;
    }
    &:active {
      box-shadow: inset 0 2px 3px #eee;
    }
    &[disabled] {
      cursor: auto;
    }
  }
}

.data-list-counts {
  span {
    font-weight: bold;
    color: #090;
  }
}


.ReactCrop__image {
  max-height: 50vh;
}

.ReactCrop__crop-selection {
  background: url("/img/crop_circle.png");
  background-size: 100%;
}

.Guards {
  margin-top: 10px;
  display: flex;
  display: none;
  flex-direction: row;
  justify-content: stretch;
}

.Guards > div {
  flex-grow: 1;
}
.Guards select {
  width: 96%;
}

.filter-buttons  {
  background: initial;
  box-shadow: initial;
  flex-wrap: wrap;
  width: auto;
  button {
    opacity: 0.7;
    box-shadow: 0 2px 5px #eee;
    border-radius: 5px;
    margin: 5px;
    background: #fff;
    border-left: initial;

    &.active {
      box-shadow: 0 2px 5px #ddd;
      opacity: 1;
      font-weight: 700;
      background: #fff;
    }
  }
}
